import React, { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Card, Stack, Link, Container, Typography, Box } from '@mui/material'
import Page from '../components/Page'
import { MHidden } from '../components/@material-extend'
import { LoginForm } from '../components/authentication/login'
import AuthSocial from '../components/authentication/AuthSocial'
import LArt from '../assets/illustration_login.svg'
import { useSnackbar } from '../layouts/dashboard/SnackbarContext'
import ForgotPassword from '../pages/ForgotPasswordDialog'

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#e1e9fe'
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

const BlurOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(10px)',
  zIndex: theme.zIndex.modal - 1
}))

export default function Login () {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard', { replace: true })
    }
  }, [navigate])

  return (
    <RootStyle title='Login | Klink' sx={{ backgroundColor: '#030303' }}>
      <MHidden width='mdDown'>
        <SectionStyle sx={{ backgroundColor: '#030303' }}>
          <Typography
            variant='h3'
            sx={{ px: 5, mt: 10, mb: 5, color: 'white' }}
          >
            Welcome to Admin panel
          </Typography>
          <img
            src={LArt}
            alt='login'
            style={{ width: '430px', padding: '20px', marginLeft: '85px' }}
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth='sm'>
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant='h4' gutterBottom>
              Sign in to KlinkBot Admin
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Enter your details below.
            </Typography>
          </Stack>
          <LoginForm />

          <Typography variant='body2' align='center' sx={{ mt: 1 }}>
            Forgot your password?&nbsp;
            <Link
              variant='subtitle2'
              component='button'
              onClick={() => setForgotPasswordOpen(true)}
              sx={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              Reset it
            </Link>
          </Typography>
        </ContentStyle>
      </Container>

      {forgotPasswordOpen && <BlurOverlay />}
      <ForgotPassword
        open={forgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
      />
    </RootStyle>
  )
}
