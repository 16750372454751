import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  TextField,
  Pagination,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Paper,
  FormControlLabel,
  Switch,
  MenuItem as MuiMenuItem,
  Checkbox,
  CircularProgress
} from '@mui/material'
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'
import { backendServices } from '../services/backendService'

const TABLE_HEAD = [
  { id: 'userId', label: 'User ID', alignRight: false },
  { id: 'userName', label: 'Username', alignRight: false },
  { id: 'points', label: 'Points', alignRight: false },
  { id: 'league', label: 'League', alignRight: false },
  { id: 'isUserVerified', label: 'Verified', alignRight: false },
  { id: 'numberOfTickets', label: 'Tickets', alignRight: false },
  { id: 'userLifeTimePoints', label: 'Lifetime Points', alignRight: false },
  { id: 'isBlockListed', label: 'Blacklisted', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
]

const UserListToolbar = ({
  filterName,
  onFilterName,
  isBlockedFilter,
  onBlockedFilterChange,
  selected,
  onBulkBlocklist,
  onBulkUnblock,
  allSelectedBlocked
}) => (
  <Stack
    direction='row'
    spacing={2}
    style={{ padding: '20px' }}
    justifyContent='space-between'
    alignItems='center'
    className='toolbar'
  >
    <Stack direction='row' spacing={2}>
      <TextField
        label='Filter by Username'
        variant='outlined'
        size='medium'
        value={filterName}
        onChange={onFilterName}
      />
      <FormControl variant='outlined' size='medium' style={{ width: '200px' }}>
        <InputLabel>Filter by Blacklist Status</InputLabel>
        <Select
          value={isBlockedFilter}
          onChange={onBlockedFilterChange}
          label='Filter by Blacklist Status'
        >
          <MenuItem value='all' style={{ background: 'black' }}>
            All Users
          </MenuItem>
          <MenuItem value='blocked' style={{ background: 'black' }}>
            Blacklisted Users
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
    <Stack direction='row' spacing={2}>
      <Button
        variant='contained'
        color='primary'
        onClick={onBulkBlocklist}
        disabled={selected.length === 0 || allSelectedBlocked}
        // disabled={selected.length === 0}
      >
        Blocklist Selected
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={onBulkUnblock}
        disabled={selected.length === 0 || !allSelectedBlocked}
      >
        Unblock Selected
      </Button>
    </Stack>
  </Stack>
)

const UserList = ({
  refreshTrigger,
  onView,
  onEdit,
  onDelete,
  onToggleBlacklist
}) => {
  const [users, setUsers] = useState([])
  const [filterName, setFilterName] = useState('')
  const [isBlockedFilter, setIsBlockedFilter] = useState('all')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('userName')
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(50)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [totalPages, setTotalPages] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)
  const [selected, setSelected] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [allSelectedBlocked, setAllSelectedBlocked] = useState(false)
  const [loading, setLoading] = useState(true)

  const containerRef = useRef(null)
  const [tableHeight, setTableHeight] = useState('100%')

  useLayoutEffect(() => {
    const updateTableHeight = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.clientHeight
        const toolbarElement = containerRef.current.querySelector('.toolbar')
        const paginationElement = containerRef.current.querySelector(
          '.pagination-container'
        )

        const toolbarHeight = toolbarElement ? toolbarElement.clientHeight : 0
        const paginationHeight = paginationElement
          ? paginationElement.clientHeight
          : 0

        const newTableHeight =
          containerHeight - toolbarHeight - paginationHeight
        setTableHeight(`${newTableHeight}px`)
      }
    }

    // Add a small delay to ensure all elements are rendered
    const timeoutId = setTimeout(updateTableHeight, 0)

    window.addEventListener('resize', updateTableHeight)

    return () => {
      window.removeEventListener('resize', updateTableHeight)
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [refreshTrigger, page, filterName, order, orderBy, isBlockedFilter])

  const fetchUsers = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/user/allUser?page=${page}&limit=${rowsPerPage}&search=${filterName}&sortBy=${orderBy}&sortOrder=${order}&isBlockedFilter=${isBlockedFilter}`
      )
      setUsers(response.data.users || [])
      setTotalPages(response.data.totalPages)
      setTotalUsers(response.data.totalUsers)
      updateSelectedBlockedStatus(selected)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      // setLoading(false); // Hide loader after fetch completes or fails
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(1)
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value)
    setPage(1)
  }

  const handleBlockedFilterChange = event => {
    setIsBlockedFilter(event.target.value)
    setPage(1)
  }

  const handleToggleBlacklist = async (userId, currentStatus) => {
    try {
      await onToggleBlacklist(userId, !currentStatus)
      fetchUsers()
    } catch (error) {
      console.error('Error toggling blacklist status:', error)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOpenMenu = (event, user) => {
    setAnchorEl(event.currentTarget)
    setSelectedUser(user)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedUser(null)
  }

  const handleView = () => {
    if (selectedUser) {
      onView(selectedUser.userId)
    }
    handleCloseMenu()
  }

  const handleEdit = () => {
    if (selectedUser) {
      onEdit(selectedUser)
    }
    handleCloseMenu()
  }

  const handleDelete = () => {
    if (selectedUser) {
      onDelete(selectedUser.userId)
    }
    handleCloseMenu()
  }

  const updateSelectedBlockedStatus = selectedIds => {
    if (selectedIds.length === 0) {
      setAllSelectedBlocked(false)
      return
    }

    const allBlocked = selectedIds.every(
      id => users.find(user => user.userId === id)?.isBlockListed
    )
    setAllSelectedBlocked(allBlocked)
  }

  const handleSelectAll = event => {
    if (event.target.checked) {
      const newSelecteds = users.map(n => n.userId)
      setSelected(newSelecteds)
      updateSelectedBlockedStatus(newSelecteds)
      setSelectAll(true)
    } else {
      setSelected([])
      setSelectAll(false)
    }
  }

  const handleClick = (event, userId) => {
    const selectedIndex = selected.indexOf(userId)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
    updateSelectedBlockedStatus(newSelected)
    setSelectAll(newSelected.length === users.length)
  }

  const handleBulkBlocklist = async () => {
    try {
      await Promise.all(
        selected.map(userId =>
          backendServices.Put(
            `${process.env.REACT_APP_BASE_URL}/user/updateUser/${userId}`,
            { isBlockListed: true }
          )
        )
      )
      fetchUsers()
      setSelected([])
      setSelectAll(false)
      console.log('Users blocklisted successfully')
    } catch (error) {
      console.error('Error blocklisting users:', error)
    }
  }

  const handleBulkUnblock = async () => {
    try {
      await Promise.all(
        selected.map(userId =>
          backendServices.Put(
            `${process.env.REACT_APP_BASE_URL}/user/updateUser/${userId}`,
            { isBlockListed: false }
          )
        )
      )
      fetchUsers()
      setSelected([])
      setSelectAll(false)
      console.log('Users unblocked successfully')
    } catch (error) {
      console.error('Error unblocking users:', error)
    }
  }

  return (
    <div
      ref={containerRef}
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Card
        style={{
          background: 'black',
          flex: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <UserListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          isBlockedFilter={isBlockedFilter}
          onBlockedFilterChange={handleBlockedFilterChange}
          selected={selected}
          onBulkBlocklist={handleBulkBlocklist}
          onBulkUnblock={handleBulkUnblock}
          allSelectedBlocked={allSelectedBlocked}
        />
        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100vh'
            marginTop='-50px'
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Paper sx={{ flex: 1, overflow: 'hidden', background: 'black' }}>
              <TableContainer sx={{ height: tableHeight }}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding='checkbox'
                        style={{ background: 'black' }}
                      >
                        <Checkbox
                          color='primary'
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < users.length
                          }
                          checked={
                            users.length > 0 && selected.length === users.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      {TABLE_HEAD.map(headCell => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.alignRight ? 'right' : 'left'}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                          style={{ backgroundColor: 'black', color: '#674eff' }}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.length > 0 ? (
                      users.map(row => {
                        const isItemSelected =
                          selected.indexOf(row.userId) !== -1
                        return (
                          <TableRow
                            hover
                            key={row.userId}
                            tabIndex={-1}
                            role='checkbox'
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding='checkbox'>
                              <Checkbox
                                color='primary'
                                checked={isItemSelected}
                                onChange={event =>
                                  handleClick(event, row.userId)
                                }
                                onClick={event => event.stopPropagation()}
                              />
                            </TableCell>
                            <TableCell align='left'>{row.userId}</TableCell>
                            <TableCell align='left'>{row.userName}</TableCell>
                            <TableCell align='left'>{row.points}</TableCell>
                            <TableCell align='left'>{row.league}</TableCell>
                            <TableCell align='left'>
                              {row.isUserVerified ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell align='left'>
                              {row.numberOfTickets}
                            </TableCell>
                            <TableCell align='left'>
                              {row.userLifeTimePoints}
                            </TableCell>
                            <TableCell align='left'>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={row.isBlockListed}
                                    onChange={() =>
                                      handleToggleBlacklist(
                                        row.userId,
                                        row.isBlockListed
                                      )
                                    }
                                    color='primary'
                                  />
                                }
                                label={row.isBlockListed ? 'Yes' : 'No'}
                              />
                            </TableCell>
                            <TableCell align='left'>
                              <IconButton
                                aria-controls='user-menu'
                                aria-haspopup='true'
                                onClick={event => handleOpenMenu(event, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                sx={{
                                  '& .MuiMenu-paper': {
                                    backgroundColor: 'black'
                                  }
                                }}
                              >
                                <MenuItem onClick={handleView}>
                                  <ListItemIcon>
                                    <VisibilityIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='View' />
                                </MenuItem>
                                {/* <MenuItem onClick={handleEdit}>
                                  <ListItemIcon>
                                    <EditIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='Edit' />
                                </MenuItem> */}
                                {/* <MenuItem onClick={handleDelete}>
                                  <ListItemIcon>
                                    <DeleteIcon />
                                  </ListItemIcon>
                                  <ListItemText primary='Delete' />
                                </MenuItem> */}
                              </Menu>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={TABLE_HEAD.length} align='center'>
                          No users found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}

        <Stack
          spacing={2}
          direction='row'
          justifyContent='center'
          mt={2}
          className='pagination-container'
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color='primary'
            showFirstButton
            showLastButton
          />
        </Stack>
      </Card>
    </div>
  )
}

const ViewUser = ({ open, onClose, user }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Make the modal slightly transparent
          color: 'white',
          boxShadow: 'none', // Remove the shadow
          border: '1px solid gray'
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0)' } // Lighten the backdrop to make the background more visible
      }}
    >
      <DialogTitle style={{ background: 'black', color: 'white' }}>
        User Details
      </DialogTitle>
      <DialogContent style={{ background: 'black', color: 'white' }}>
        {user ? (
          <div>
            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>User ID:</strong> {user.userId}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Username:</strong> {user.userName}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>First Name:</strong> {user.firstName}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Last Name:</strong> {user.lastName}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
              sx={{
                overflowX: 'auto',
                maxWidth: '100%'
              }}
            >
              <Typography
                variant='body1'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <strong>userProfile:</strong> {user.userProfile}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>userPremium:</strong> {user.userPremium ? 'Yes' : 'No'}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Email:</strong> {user.email}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Points:</strong> {user.points}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>League:</strong> {user.league}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>ReferredBy:</strong> {user.referredBy}
              </Typography>
            </Box>
            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'white' }} id='referrals-info-label'>
                  ReferralsInfo
                </InputLabel>
                <Select
                  labelId='referrals-info-label'
                  id='referrals-info'
                  value={user.referralsInfo[0]?.username || ''}
                  sx={{ color: 'white' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        backgroundColor: 'black'
                      }
                    },
                    MenuListProps: {
                      style: {
                        maxHeight: 300,
                        overflowY: 'auto'
                      }
                    }
                  }}
                >
                  {user.referralsInfo.map((referral, index) => (
                    <MenuItem
                      key={index}
                      value={referral.username}
                      style={{ background: 'black', color: 'white' }}
                    >
                      {referral.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Referrals count:</strong> {user.referrals}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Visited:</strong> {user.isVisited ? 'Yes' : 'No'}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Verified:</strong> {user.isUserVerified ? 'Yes' : 'No'}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Number of Tickets:</strong> {user.numberOfTickets}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Lifetime Points:</strong> {user.userLifeTimePoints}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Current Energy:</strong> {user.currentEnergy}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Max Energy:</strong> {user.maxEnergy}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Total Taps:</strong> {user.totalTaps}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>League Reward:</strong> {user.leagueReward}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Total Task Rewards:</strong> {user.totalTaskRewards}
              </Typography>
            </Box>
            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Source:</strong> {user.source}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Last Active:</strong>{' '}
                {user.lastActive
                  ? new Date(user.lastActive).toLocaleString()
                  : 'N/A'}
              </Typography>
            </Box>

            <Box
              border={1}
              borderColor='gray'
              padding={1}
              marginBottom={2}
              borderRadius={1}
            >
              <Typography variant='body1'>
                <strong>Created At:</strong>{' '}
                {user.createdAt
                  ? new Date(user.createdAt).toLocaleString()
                  : 'N/A'}
              </Typography>
            </Box>
          </div>
        ) : (
          <Typography>No user details available</Typography>
        )}
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const EditUser = ({ open, onClose, user, onSave }) => {
  const [editedUser, setEditedUser] = useState(user || {})

  useEffect(() => {
    setEditedUser(user || {})
  }, [user])

  const handleChange = event => {
    const { name, value, checked } = event.target
    setEditedUser(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSave = () => {
    if (editedUser) {
      onSave(editedUser)
    }
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Make the modal slightly transparent
          color: 'white',
          boxShadow: 'none', // Remove the shadow
          border: '1px solid gray'
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop to make the background more visible
      }}
    >
      <DialogTitle style={{ background: 'black', color: 'white' }}>
        Edit User
      </DialogTitle>
      <DialogContent style={{ background: 'black', color: 'white' }}>
        {editedUser ? (
          <div>
            <TextField
              label='Username'
              name='userName'
              value={editedUser.userName || ''}
              onChange={handleChange}
              fullWidth
              margin='normal'
            />

            <TextField
              label='Points'
              name='points'
              type='number'
              value={editedUser.points || ''}
              onChange={handleChange}
              fullWidth
              margin='normal'
            />

            <TextField
              label='League'
              name='league'
              value={editedUser.league || ''}
              onChange={handleChange}
              fullWidth
              margin='normal'
            />

            <TextField
              label='Lifetime Points'
              name='userLifeTimePoints'
              type='number'
              value={editedUser.userLifeTimePoints || ''}
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
          </div>
        ) : (
          <Typography>No user details available</Typography>
        )}
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={handleSave} color='primary'>
          Save
        </Button>
        <Button onClick={onClose} color='secondary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ConfirmDeleteDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth='xs' // Keep the dialog compact
    PaperProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
        color: 'white',
        boxShadow: 'none', // Remove the shadow
        border: '1px solid gray'
      }
    }}
    BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighter backdrop for more visibility of the background
    }}
  >
    <DialogTitle style={{ background: 'black', color: 'white' }}>
      Confirm Delete
    </DialogTitle>
    <DialogContent style={{ background: 'black', color: 'white' }}>
      <Typography>Are you sure you want to delete this user?</Typography>
    </DialogContent>
    <DialogActions style={{ background: 'black' }}>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} color='error'>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

const UserManager = () => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [viewOpen, setViewOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)

  const handleView = async userId => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/user/${userId}`
      )
    
      const userData = response.data.data.user
      setSelectedUser(userData)
      setViewOpen(true)
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  const handleEdit = user => {
    setSelectedUser(user)
    setEditOpen(true)
  }

  const handleDelete = userId => {
    setSelectedUserId(userId)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setSelectedUserId(null)
  }

  const handleDeleteConfirm = () => {
    if (selectedUserId) {
      backendServices
        .Delete(
          `${process.env.REACT_APP_BASE_URL}/user/deleteUser/${selectedUserId}`
        )
        .then(() => {
          setRefreshTrigger(!refreshTrigger)
          alert('User deleted successfully')
          handleDeleteClose()
        })
        .catch(error => console.error('Error deleting user:', error))
    }
  }

  const handleCloseView = () => {
    setViewOpen(false)
    setSelectedUser(null)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
    setSelectedUser(null)
  }

  const handleSave = updatedUser => {
    backendServices
      .Put(
        `${process.env.REACT_APP_BASE_URL}/user/updateUser/${updatedUser.userId}`,
        updatedUser
      )
      .then(() => {
        setRefreshTrigger(prev => !prev)
        console.log('User updated successfully')
      })
      .catch(error => console.error('Error updating user:', error))
  }

  const handleToggleBlacklist = async (userId, newStatus) => {
    try {
      await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/user/updateUser/${userId}`,
        { isBlockListed: newStatus }
      )
      setRefreshTrigger(prev => !prev)
      console.log('User blacklist status updated successfully')
    } catch (error) {
      console.error('Error updating user blacklist status:', error)
    }
  }

  return (
    <Container>
      <Typography variant='h4' gutterBottom>
        Manage Users
      </Typography>
      <UserList
        refreshTrigger={refreshTrigger}
        onView={handleView}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onToggleBlacklist={handleToggleBlacklist}
      />
      <ViewUser open={viewOpen} onClose={handleCloseView} user={selectedUser} />
      <EditUser
        open={editOpen}
        onClose={handleCloseEdit}
        user={selectedUser}
        onSave={handleSave}
      />
      <ConfirmDeleteDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
      />
    </Container>
  )
}

export default UserManager
