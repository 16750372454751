import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const UserPremiumChart = () => {
  const [chartData, setChartData] = useState({ premiumCount: {}, deviceCount: {} });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/allUser`
        );

        const users = response?.data?.users || [];

        const premiumCount = users.reduce((acc, user) => {
          const isPremium = user.userPremium ? 'Premium' : 'Non-Premium';
          acc[isPremium] = (acc[isPremium] || 0) + 1;
          return acc;
        }, {});

        const deviceCount = users.reduce((acc, user) => {
          const deviceOS = user.deviceOS ? 'iOS' : 'Android';
          acc[deviceOS] = (acc[deviceOS] || 0) + 1;
          return acc;
        }, {});

        setChartData({
          premiumCount,
          deviceCount,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const mergeChartData = () => {
    const premiumLabels = Object.keys(chartData.premiumCount);
    const deviceLabels = Object.keys(chartData.deviceCount);
    const allLabels = [...new Set([...premiumLabels, ...deviceLabels])];

    const premiumData = allLabels.map(label => chartData.premiumCount[label] || 0);
    const deviceData = allLabels.map(label => chartData.deviceCount[label] || 0);

    return {
      labels: allLabels,
      datasets: [
        {
          label: 'Premium/Non-premium Users',
          data: premiumData,
          backgroundColor: '#36A2EB',
          borderColor: '#1E88E5',
          borderWidth: 1,
        },
        {
          label: 'iOS/Android Users',
          data: deviceData,
          backgroundColor: '#FF6384',
          borderColor: '#FF3D67',
          borderWidth: 1,
        },
      ],
    };
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontColor: '#FFFFFF',
      },
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItems) {
          return tooltipItems[0].label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          barPercentage: 0.4,
          categoryPercentage: 1,
          ticks: {
            display: true,
            fontColor: '#FFFFFF',
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: '#FFFFFF',
            padding: 10,
          },
        },
      ],
    },
    elements: {
      bar: {
        barPercentage: 0.3,
        categoryPercentage: 1,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: 'User Data Chart',
        fontColor: '#FFFFFF',
        fontSize: 18,
        padding: {
          top: 20,
          bottom: 20,
        },
      },
    },
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Bar
        data={mergeChartData()}
        options={barChartOptions}
      />
    </div>
  );
};

export default UserPremiumChart;
