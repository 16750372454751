import React, { useState } from 'react'
import axios from 'axios'
import { Typography, TextField, Button, Box, Modal } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSnackbar } from '../layouts/dashboard/SnackbarContext'

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: theme.zIndex.modal
}))

const ForgotPassword = ({ open, onClose }) => {
  const [email, setEmail] = useState('')
  const { showSnackbar } = useSnackbar()

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/forgot-password`,
        { email }
      )
      showSnackbar('Reset link sent to your email!')
      onClose()
    } catch (error) {
      showSnackbar(
        error.response?.data?.message || 'An error occurred',
        'error'
      )
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='forgot-password-modal'
      aria-describedby='forgot-password-modal-description'
    >
      <ModalContent style={{ background: 'black' }}>
        <Typography variant='h4' gutterBottom>
          Forgot Password
        </Typography>
        <Typography variant='body1' color='text.secondary' sx={{ mb: 4 }}>
          Enter your email address and we'll send you a link to reset your
          password.
        </Typography>
        <Box
          component='form'
          onSubmit={handleSubmit}
          sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <TextField
            type='email'
            label='Enter your email'
            variant='outlined'
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <Button type='submit' variant='contained' color='primary'>
            Send Reset Link
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default ForgotPassword
