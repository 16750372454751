import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';

const LanguageCharts = () => {
  const [localeData, setLocaleData] = useState({});

  useEffect(() => {
    const fetchLocaleData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/user/allUser`
        );

        // Extract locale data and count occurrences
        const users = response?.data?.users || [];
        const localeCount = users.reduce((acc, user) => {
          const locale = user.locale || 'Unknown';
          acc[locale] = (acc[locale] || 0) + 1;
          return acc;
        }, {});

        setLocaleData(localeCount);
      } catch (error) {
        console.error('Error fetching locale data:', error);
      }
    };

    fetchLocaleData();
  }, []);

  const createChartData = (data, colors) => {
    return {
      labels: Object.keys(data),
      datasets: [
        {
          data: Object.values(data),
          backgroundColor: colors,
        },
      ],
    };
  };

  const pieDoughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontColor: '#FFFFFF', // Set legend label color to white
      },
    },
  };

  return (
  
      <CardContent className="h-64">
        <Doughnut
          data={createChartData(localeData, ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'])}
          options={pieDoughnutChartOptions}
        />
      </CardContent>

  );
};

export default LanguageCharts;
