// import React, { useState, useEffect } from 'react';
// import { Typography } from '@mui/material';
// import axios from 'axios';
// import { ComposableMap, Geographies, Geography , Marker} from "react-simple-maps";
// import Tooltip from "@mui/material/Tooltip";
// import { feature } from "topojson-client";
// import { styled } from '@mui/material/styles';
// import LanguageCharts from './LanguagesCharts';
// import UserPremiumChart from './userPremium';

// const GEOJSON_URL = "/features.json";

// const CustomTooltip = styled(Tooltip)(({ theme }) => ({
//     '.MuiTooltip-tooltip': {
//         fontSize: '0.875rem !important',
//         color: '#000 !important', // Set text color to black
//         backgroundColor: '#ffffff !important', // Set background color
//         border: '1px solid #ddd' // Set border color
//     }
// }));

// const Card = ({ children }) => (
//     <div className="border rounded-lg shadow-sm p-4 bg-white">
//         {children}
//     </div>
// );

// const CardContent = ({ children, className }) => (
//     <div className={`flex-1 ${className}`}>
//         {children}
//     </div>
// );

// const DemographicsChart = () => {
//     const [geographies, setGeographies] = useState([]);
//     const [data, setData] = useState([]);
//     const [tooltipContent, setTooltipContent] = useState("");

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 // Fetch TopoJSON data
//                 const geoResponse = await fetch(GEOJSON_URL);
//                 const topoData = await geoResponse.json();

//                 // Convert TopoJSON to GeoJSON
//                 const geoJson = feature(topoData, topoData.objects.world).features;
//                 setGeographies(geoJson);

//                 // Fetch API data
//                 const apiResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/geolocation-stats`);
//                 if (!apiResponse.data || !apiResponse.data.data) {
//                     console.error('API response does not contain data');
//                     return;
//                 }

//                 const apiData = apiResponse.data.data;
//                 setData(apiData);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     // Create a map of country names to their data counts
//     const dataMap = data.reduce((acc, item) => {
//         const countryName = item.Country === "Currently not available" ? "Others" : item.Country;
//         acc[countryName] = item.count;
//         return acc;
//     }, {});

//     // Filter geographies to only include countries present in dataMap
//     const filteredGeographies = geographies.filter(geo => {
//         const countryName = geo.properties.name;
//         return dataMap[countryName] !== undefined;
//     });

//     return (
//         <>
//             <Typography variant='h4' color='white' style={{ textAlign: "center" }}>
//                 Users Demographics
//             </Typography>
//             {/* <div style={{ width: "100%", height: "40%" }}>
//                 <ComposableMap width={800} height={600}>
//                     <Geographies geography={{ features: filteredGeographies }}>
//                         {({ geographies }) =>
//                             geographies.map((geo) => {
//                                 const countryName = geo.properties.name;
//                                 const count = dataMap[countryName] || 0;
//                                 return (
//                                     <CustomTooltip
//                                         key={geo.rsmKey}
//                                         title={`${countryName}: ${count} users`}
//                                         placement="top"
//                                         arrow
//                                         classes={{ tooltip: 'custom-tooltip' }}
//                                     >
//                                         <Geography
//                                             geography={geo}
//                                             stroke="#000"
//                                             fill={count ? "#674eff" : "#fff"} // Use color based on data count
//                                             onMouseEnter={() => setTooltipContent(`${countryName}: ${count} users`)}
//                                             onMouseLeave={() => setTooltipContent("")}
//                                             style={{
//                                                 default: {
//                                                     outline: "none",
//                                                     background: "black !important",
//                                                     color: "white !important",
//                                                 },
//                                                 hover: {
//                                                     fill: "#674eff",
//                                                     transition: "all 0.3s",
//                                                     outline: "none",
//                                                     color: "black",
//                                                 },
//                                                 pressed: {
//                                                     outline: "none",
//                                                 },
//                                             }}
//                                         />
//                                     </CustomTooltip>
//                                 );
//                             })
//                         }
//                     </Geographies>
//                 </ComposableMap>
//             </div> */}
//          <div style={{ width: "100%", height: "40%" }}>
//                 <ComposableMap width={800} height={600}>
//                     <Geographies geography={{ features: filteredGeographies }}>
//                         {({ geographies }) =>
//                             geographies.map((geo) => {
//                                 const countryName = geo.properties.name;
//                                 const count = dataMap[countryName] || 0;
//                                 return (
//                                     <React.Fragment key={geo.rsmKey}>
//                                         <CustomTooltip
//                                             title={`${countryName}: ${count} users`}
//                                             placement="top"
//                                             arrow
//                                             classes={{ tooltip: 'custom-tooltip' }}
//                                         >
//                                             <Geography
//                                                 geography={geo}
//                                                 stroke="#000"
//                                                 fill={count ? "#674eff" : "#fff"} // Use color based on data count
//                                                 onMouseEnter={() => setTooltipContent(`${countryName}: ${count} users`)}
//                                                 onMouseLeave={() => setTooltipContent("")}
//                                                 style={{
//                                                     default: {
//                                                         outline: "none",
//                                                         background: "black !important",
//                                                         color: "white !important",
//                                                     },
//                                                     hover: {
//                                                         fill: "#674eff",
//                                                         transition: "all 0.3s",
//                                                         outline: "none",
//                                                         color: "black",
//                                                     },
//                                                     pressed: {
//                                                         outline: "none",
//                                                     },
//                                                 }}
//                                             />
//                                         </CustomTooltip>

//                                         {/* Add marker only if data is available for the country */}
//                                         {count > 0 && geo.properties.latitude && geo.properties.longitude && (
//                                             <Marker coordinates={[geo.properties.longitude, geo.properties.latitude]}>
//                                                 <circle r={5} fill="#FF5533" />
//                                                 <text
//                                                     textAnchor="middle"
//                                                     style={{
//                                                         fontFamily: "system-ui",
//                                                         fill: "#5D5A6D",
//                                                         fontSize: "10px",
//                                                         pointerEvents: "none",
//                                                     }}
//                                                 >
//                                                     {countryName}
//                                                 </text>
//                                             </Marker>
//                                         )}
//                                     </React.Fragment>
//                                 );
//                             })
//                         }
//                     </Geographies>
//                 </ComposableMap>
//             </div>
//             <Card style={{ marginBottom: "10px" }}>
//                 <Typography variant='h4' color='white'>
//                     Language Preference
//                 </Typography>
//                 <CardContent className="h-64" style={{ marginBottom: "10px" }}>
//                     <LanguageCharts />
//                 </CardContent>
//             </Card>
//             <Card style={{ marginBottom: "10px" }}>
//                 <Typography variant='h4' color='white'>
//                     Premium and Non-premium users
//                 </Typography>
//                 <CardContent className="h-64">
//                     <UserPremiumChart />
//                 </CardContent>
//             </Card>
//         </>
//     );
// };

// export default DemographicsChart;




import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import axios from 'axios';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import Tooltip from "@mui/material/Tooltip";
import { feature } from "topojson-client";
import { styled } from '@mui/material/styles';
import LanguageCharts from './LanguagesCharts';
import UserPremiumChart from './userPremium';

const GEOJSON_URL = "/features.json";

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
    '.MuiTooltip-tooltip': {
        fontSize: '0.875rem !important',
        color: '#000 !important', // Set text color to black
        backgroundColor: '#ffffff !important', // Set background color
        border: '1px solid #ddd' // Set border color
    }
}));

const Card = ({ children }) => (
    <div className="border rounded-lg shadow-sm p-4 bg-white">
        {children}
    </div>
);

const CardContent = ({ children, className }) => (
    <div className={`flex-1 ${className}`}>
        {children}
    </div>
);

const DemographicsChart = () => {
    const [geographies, setGeographies] = useState([]);
    const [data, setData] = useState([]);
    const [tooltipContent, setTooltipContent] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch TopoJSON data
                const geoResponse = await fetch(GEOJSON_URL);
                const topoData = await geoResponse.json();

                // Convert TopoJSON to GeoJSON
                const geoJson = feature(topoData, topoData.objects.world).features;
                setGeographies(geoJson);

                // Fetch API data
                const apiResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/geolocation-stats`);
                if (!apiResponse.data || !apiResponse.data.data) {
                    console.error('API response does not contain data');
                    return;
                }

                const apiData = apiResponse.data.data;
                setData(apiData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Create a map of country names to their data counts
    const dataMap = data.reduce((acc, item) => {
        const countryName = item.Country === "Currently not available" ? "Others" : item.Country;
        acc[countryName] = item.count;
        return acc;
    }, {});

    return (
        <>
            <Typography variant='h4' color='white'>
                Users Demographics
            </Typography>
            <div style={{ width: "100%", height: "50%" }}>
               
                <ComposableMap width={800} height={600}>
                    <Geographies geography={{ features: geographies }}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const countryName = geo.properties.name;
                                const count = dataMap[countryName] || 0;
                                const fillColor = count > 0 ? "#674eff" : "#0C1844"; // Yellow if data is present, otherwise default color

                                return (
                                    <React.Fragment key={geo.rsmKey}>
                                        <CustomTooltip
                                            title={`${countryName}: ${count} users`}
                                            placement="top"
                                            arrow
                                            classes={{ tooltip: 'custom-tooltip' }}
                                        >
                                            <Geography
                                                geography={geo}
                                                stroke="#000"
                                                fill={fillColor} // Highlight countries with data in yellow
                                                onMouseEnter={() => setTooltipContent(`${countryName}: ${count} users`)}
                                                onMouseLeave={() => setTooltipContent("")}
                                                
                                                style={{
                                                    default: {
                                                        fill: fillColor, // Apply the fill color dynamically
                                                        outline: "none",
                                                    },
                                                    hover: {
                                                        fill: "#674eff",
                                                        transition: "all 0.3s",
                                                        outline: "none",
                                                    },
                                                    pressed: {
                                                        outline: "none",
                                                    },
                                                }}
                                            >

                                                </Geography>
                                        </CustomTooltip>

                
                                        
                                      
                                            
                        
                                    </React.Fragment>
                                );
                            })
                        }
                    </Geographies>
                </ComposableMap>
            </div>
            <Card style={{ marginBottom: "10px" }}>
                <Typography variant='h4' color='white'>
                    Language Preference
                </Typography>
                <CardContent className="h-64" style={{ marginBottom: "10px" }}>
                    <LanguageCharts />
                </CardContent>
            </Card>
            <Card style={{ marginBottom: "10px" }}>
                <Typography variant='h4' color='white'>
                    Premium and Non-premium users
                </Typography>
                <CardContent className="h-64">
                    <UserPremiumChart />
                </CardContent>
            </Card>
        </>
    );
};

export default DemographicsChart;
